<template>
  <div class="positionDetail content_area">
    <div class="clearfix">
      <!-- 职位投递 -->
      <div class="jobDelivery clearfix" v-loading="loading">
        <div class="fl">
          <div class="title">
            {{ positionInfo.name | textFormat }}
            <span v-if="positionInfo.salary_lowest!=0">
              {{ positionInfo.salary_lowest | textFormat }}K —
              {{ positionInfo.salary_highest==21?`20K以上`:`${positionInfo.salary_highest}K` }} 
              <!-- {{ positionInfo.salary_highest | textFormat }}K -->
            </span>
            <span v-else style="font-size:17px;">薪资面议</span>
          </div>
          <div class="desc clearfix">
            <div class="fl">
              <span
                >{{ positionInfo.region | textFormat }}
                |
              </span>
              <span
                >{{
                  positionInfo.experience | experienceFormat(LOCALE)
                }}
              </span>
              <span
                >|
                {{
                  positionInfo.level_require | levelRequireFormat("zh")
                }}</span
              >
            </div>
            <div class="fl like clearfix">
              <div class="fl">
                <i class="el-icon-view"></i> {{positionInfo.views}}
              </div>
            </div>
          </div>
        </div>
        <div
          class="fr"
          style="margin-top: 25px"
          v-show="positionInfo.status != 4"
        >
<!--          <el-button-->
<!--              type="primary"-->
<!--              size="small"-->
<!--              @click="showPositionDIalog"-->
<!--              round-->
<!--          >投递简历</el-button>-->
          <submitResume
              :position_id="position_id" @successChange="successSubmitResume"></submitResume>
        </div>
      </div>
      <div class="content fl myC_width">
        <!-- 岗位描述: -->
        <div class="jobDescribe" v-loading="loading">
          <div class="title">岗位描述</div>
          <div v-html="positionInfo.desc" class="pre-text"></div>
          <!-- <pre>{{positionInfo.desc}}</pre> -->
          <div v-if="!loading && !positionInfo.desc" class="tc">暂无描述</div>
        </div>
        <!-- 工作地址 -->
        <div class="jobAdress" v-loading="loading">
          <div class="title">工作地址</div>
          <div>{{ positionInfo.address }}</div>
          <div v-if="!loading && !positionInfo.address" class="tc">
            暂无数据
          </div>
          <div class="companyVideo" v-if="positionInfo.aliyun_id">
            <div class="mask">
              <i
                class="el-icon-video-play iconPlay cursor"
                @click="playVideo(positionInfo.aliyun_id)"
              ></i>
            </div>
            <el-image
              v-show="positionInfo.cover_url"
              :src="positionInfo.cover_url"
              style="width: 300px; height: 200px"
              class="img"
            ></el-image>
          </div>
        </div>
		<!-- 联系方式 -->
		<div class="connect" v-loading="loading">
			  <div class="title">联系方式</div>
			  <div class="item">
				<span>姓名</span>
				<span class="textOverflow" v-if="positionInfo.contact">{{JSON.parse(positionInfo.contact).name|textFormat}}</span>
			  </div>
			  <div  class="item">
				<span>邮箱</span>
				<span class="textOverflow" v-if="positionInfo.contact">{{JSON.parse(positionInfo.contact).email|textFormat}}</span>
				<!-- <span class="textOverflow bule" @click="showConcat">******</span> -->
			  </div>
			  <div class="item">
				<span>电话</span>
        <span class="textOverflow" v-if="positionInfo.contact">{{JSON.parse(positionInfo.contact).mobile|textFormat}}</span>
				<!-- <span class="textOverflow bule" @click="showConcat">******</span> -->
			  </div>
		</div>
	  </div>
      <div class="fr history">
        <companyDetail :info="positionInfo" />
        <!-- <div class="jobPost" v-show="positionList.length > 0">
          <jobPost :positionList="positionList" />
        </div> -->
      </div>
    </div>
    <div class="otherJob" v-loading="sameLoading">
      <div class="title">相似职位</div>
      <div class="clearfix">
        <div v-for="(it, index) in sameList" class="otherItem fl" :key="index">
          <otherJobItem :sameItem="it"></otherJobItem>
        </div>
      </div>
      <noDataImg v-if="!sameLoading && sameList.length == 0"></noDataImg>
    </div>
    <publicNav />
    <positionDialog :id="id" ref="positionDialog"></positionDialog>
    <videoPlay ref="videoPlay" class="itemBox"></videoPlay>
    <!-- <el-dialog
      title="请联系秘书处"
      :visible.sync="dialogVisible"
      width="600px"
      
    >
      <div class="concatList">
          <div class="concatItem" v-for="(item,index) in tel" :key="index">
              {{item.lable}}{{item.content}}
          </div>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import positionDialog from "~hbs/components/position/positionDialog";
import otherJobItem from "~hbs/components/position/otherJobItem";
import jobPost from "~hbs/components/position/jobPost";
import companyDetail from "~hbs/components/position/companyDetail";
import publicNav from "~hbs/components/index/publicNav";
import orderTalk from "~hbs/components/button/orderTalk";
import submitResume from "~hbs/components/button/submitResume";
import videoPlay from "@/baseComponents/videoPlay";

export default {
  metaInfo: {
    title: "职位详情",
  },
  components: {
    otherJobItem,
    jobPost,
    companyDetail,
    publicNav,
    submitResume,
    orderTalk,
    videoPlay,
    positionDialog
  },
  data() {
    return {
      id:null,
      currentLike: false,
      orderVisible: false,
      loading: false,
      sameLoading: false,
      btnLoading: false,
      position_id: null,
      positionInfo: {},
      positionList: [],
      sameList: [],
      orderShow: 1,
      nolike:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/like.png',
      like: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/like1.png',
       tel:[
        {
          lable: "入会联系：",
          content: "021-68892891",
        },
        {
          lable: "业务咨询：",
          content: "021-68892892",
        },
        {
          lable: "培训联系：",
          content: "021-68892894",
        },
        {
          lable: "赞助联系：",
          content: "021-68892893",
        },
        {
          lable: "跨境贸易分会联系：",
          content: "021-20953528",
        },
        {
          lable: "空运分会联系：",
          content: "021-58102609",
        },
      ],
      dialogVisible:false,
    };
  },
  watch: {
    $route(to, from) {
      this.position_id = this._decode(this.$route.query.parameter).id;
      this.getList();
    },
  },
  methods: {
    async lookOnce(){
      let data = await this.$store.dispatch(
            "API_position/lookOnce",
            {position_id:this.position_id}
          );
    },
    showPositionDIalog() {
      this.id = this.positionInfo.id;
      this.$refs.positionDialog.dialogVisible = true;
    },
     showConcat() {
      this.dialogVisible = true
    },
    orderVisibleShow(val) {
      if (val) {
        this.orderVisible = true;
      } else {
        this.orderVisible = false;
      }
    },
    async getList() {
      this.loading = true;
      this.sameLoading = true;
      let params = {
        position_id: this.position_id,
        source:this.PJSource,
        user_id: this.USER_INFO.id,
      };
      let result = await Promise.all([
        this.$store.dispatch("API_position/jobPositionDetail", params),
        this.$store.dispatch("API_booth/viewPosition", {
          start: 0,
          limit: 5,
          nopage: 0,
          source:this.PJSource,
          user_id: this.USER_INFO.id,
        }),
        this.$store.dispatch("API_position/jobRecommendSame", {
          position_id: this.position_id,
          source:this.PJSource,
          limit: 6,
        }),
        this.$store.dispatch("API_position/historyAdd", {
          position_id: this.position_id,
          source:this.PJSource,
          user_id: this.USER_INFO.id,
        }),
      ]);
      console.log(result);
      if (result[0]&&result[0].success) {
        this.loading = false;
        this.positionInfo = result[0].data;
        this.currentLike = this.positionInfo.position_collection_id
          ? true
          : false;
          this.lookOnce()
      } else {
        this.loading = false;
      }
      if (result[1]&&result[1].success) {
        this.loading = false;
        this.positionList = result[1].data;
      }
      if (result[2]&&result[2].success) {
        this.sameLoading = false;
        this.sameList = result[2].data;
      }
    },
    async getStatus() {
      let result = await this.$store.dispatch(
        "API_position/getPositionStatus",
        {
          position_id: this.position_id,
          source:this.PJSource,
          user_id: this.USER_INFO.id,
        }
      );
      if (result.success) {
        let status = result.data && result.data.status;
        if (JSON.stringify(result.data) != "{}") {
          if (status === 0 || status === 1) {
            this.orderShow = 2;
          } else {
            this.orderShow = 3;
          }
        }
      }
    },
    async likeChange() {
      let params = {
        position_id: this.position_id,
        user_id: this.USER_INFO.id,
        source:this.PJSource,
      };
      if (this.currentLike) {
        params.deleted = 1;
        params.job_position_collection_id = this.positionInfo.position_collection_id;
      }
      let res = await this.$store.dispatch(
        "API_position/JobCollection",
        params
      );
      if (res.success) {
        this.currentLike = !this.currentLike;
      }
    },
    playVideo(id) {
      this.$refs.videoPlay.startPlay(id);
    },
    btnLoadingChange(val) {
      this.btnLoading = val;
    },
    successSubmitResume(){

    }
  },
  mounted() {
    // this.INFO_MATION();
    this.position_id = this._decode(this.$route.query.parameter).id;
    this.getList();
    // this.getStatus();
  },
};
</script>
<style lang="less" scoped>
.concatList{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100px;
}
.concatItem{
  width: 230px;
}

.positionDetail {
  padding: 24px 0;
  .jobDelivery {
    height: 120px;
    background: #ffffff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    padding: 20px 30px;
    .title {
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 6px;
      span {
        color: #ff2b0d;
        margin-left: 10px;
      }
    }
    .desc {
      .like {
        margin-left: 30px;
        .img {
          width: 14px;
          height: 12px;
          margin: 4px 5px;
        }
      }
    }
  }
  .content {
    width: 888px;
  }
  .myC_width{
    width: 888px !important;
  }
  .jobDescribe {
    padding: 20px 30px;
    min-height: 200px;
    background: #ffffff;
    margin: 16px 0;
    font-size: 14px;
    .title {
      font-size: 18px;
      margin-bottom: 24px;
      font-weight: 500;
    }
  }
  .history {
    width: 300px;
  }
  
  .connect{
      padding: 20px 30px;
      margin-bottom: 24px;
      background: #ffffff;
      font-size: 14px;
      .title {
        font-size: 18px;
        margin-bottom: 24px;
        font-weight: 500;
      }
      .item{
        display: flex;
        align-items: center;
        span{
          line-height: 40px;
          font-size: 16px;
          box-sizing: border-box;
          color: #333;
        }
        span:first-child{
          padding-right:15px;
        }
        span:nth-child(2){
          width: 300px;
        }
      }
    }
  .jobAdress {
    padding: 20px 30px;
    margin-bottom: 24px;
    background: #ffffff;
    min-height: 300px;
    font-size: 14px;
    .title {
      font-size: 18px;
      margin-bottom: 24px;
      font-weight: 500;
    }

    .companyVideo {
      width: 300px;
      height: 200px;
      border: 1px solid #ececec;
      margin-top: 30px;
      position: relative;
      .mask {
        position: absolute;
        top: 0;
        width: 300px;
        height: 200px;
        z-index: 2;
        background: rgba(0, 0, 0, 0.3);
        &:hover {
          background: rgba(0, 0, 0, 0.5);
          i {
            color: #ececec;
          }
        }
      }
      .iconPlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 45px;
        color: #dbd8d8;
      }
    }
  }
  .otherJob {
    min-height: 300px;
    position: relative;
    .title {
      font-size: 18px;
      margin-bottom: 16px;
      font-weight: 500;
    }
    .otherItem {
      width: 391px;
      height: 200px;
      border: 1px solid #e9e9e9;
      margin-right: 12px;
      background: #fff;
      margin-bottom: 16px;
      padding: 32px 16px 26px;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .jobPost {
    margin-top: 16px;
  }
}
.pre-text {
  white-space: pre-wrap;
  word-wrap: break-word;
}
/deep/.el-dialog__headerbtn {
  top: 10px;
}
.bule{
  color: #0083f6 !important;
  cursor: pointer;
}
</style>
